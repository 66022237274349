import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Footer = ({ copyrights }) => (
  <footer>
    <div>
      <Link
        to="/"
        style={{ textDecoration: `none` }}
      >
        { copyrights }
      </Link>
    </div>
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer
