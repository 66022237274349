import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

import style from '../styles/top.module.css'
import { StaticQuery, graphql } from 'gatsby'

const Top = ({
  title,
  subtitle,
}) => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "top.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        Tag="div"
        className={style.container}
        fluid={data.image.childImageSharp.fluid}
      >
        <h3 className={style.subtitle}>
          {subtitle}
        </h3>
      </BackgroundImage>
    )}
  />
)

Top.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Top
